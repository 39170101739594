import React from "react";

function Servicos() {
  return (
    <div className="home">
      <div className="container">
        <div className="container3">
          <h1>Serviços</h1>
        </div>
        <div className="container2">
            <ul>
              <li><span className="aceso">Sites:</span> HTML5 - CSS3 - React JS - Bootstrap - Joomla - Wordpress</li>
              <li><span className="aceso">Sistemas:</span> PHP - Laravel - Javascript</li>
              <li><span className="aceso">E-commerces:</span> Opencart - Prestashop - WooCommerce</li>
              <li><span className="aceso">Social Media</span> e <span className="aceso">Edição de Vídeos</span></li>
            </ul>
          </div>
      </div>
    </div>
  );
}

export default Servicos;