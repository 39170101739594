import React from "react";
import js_logo from './logo_js.png';
import php_logo from './logo_php.png';
import mysql_logo from './logo_mysql.png';
import './App.css';

function Home() {
  return (
    <div className="home">
        <div className="container">
          <div className="container3">
            <h1><span>Bacuri</span> agora é DIGITAL</h1>
          </div>
              <div className="container2">
                <br />
                <em>TECNOLOGIAS:</em>
                <br /><br />
                <img id="img" src={js_logo} alt="js"/>
                <img id="img2" src={php_logo} alt="php"/>
                <img id="img3" src={mysql_logo} alt="mysql"/>
              </div>
          </div>
    </div>
  );
}

export default Home;