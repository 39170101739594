import React from "react"
import { NavLink } from "react-router-dom";

function HamburgerMenuPage() {
  function handleClick(id) {
    const e = document.getElementById("menu-toggle");
    const checked = e.checked

    const objeto = document.getElementById(id);
    //desmarcar os outros links
    for (var ids=1; ids<5; ids++) {
      const objetos = document.getElementById(ids);
      objetos.setAttribute('class', 'apagado');
    }

    objeto.setAttribute('class', 'aceso_menu');
    
    //evitar que quando clicar no menu e reduzir a tela, o mesmo fique aberto
    const windowWidth = window.innerWidth 
    if (windowWidth > 700) { e.checked=0; } else {
          if (checked) {
            e.checked=0
          } else {
            e.checked=1
          }
    }
  }

  return (
    <div>
      <section className="top-nav">
        <header className="App-header">
        <a className="navbar-brand" href="http://www.bacuridigital.com">
          <span className="text-primary">BACURI</span><span className="text-light">Digital</span>
        </a>
        </header>

          <input id="menu-toggle" type="checkbox" />
          <label className="menu-button-container" htmlFor="menu-toggle">
            <div className="menu-button"></div>
          </label>
          <ul className="menu">
          <br />
              <li>
                <NavLink to="./">
                  <span className="aceso_menu" id="1" onClick={() => handleClick(1)}>Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="./quemsomos">
                <span className="apagado" id="2" onClick={() => handleClick(2)}>Quem Somos</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="./servicos">
                <span className="apagado" id="3" onClick={() => handleClick(3)}>Serviços</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="./contato">
                <span className="apagado" id="4" onClick={() => handleClick(4)}>Contato</span>
                </NavLink>
              </li>              
            </ul>
      </section>
    </div>
  )
}
export default HamburgerMenuPage