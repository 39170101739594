import React from "react";
import whats from './whats.png';

function WhatsApp() {
    return (
        <div className="whatsapp-button" z-index="50000">
            <a href="https://api.whatsapp.com/send?phone=5571993143374&text=Gostaria%20de%20um%20or%C3%A7amento" target="_blank" rel="noopener noreferrer">
		        <img id="img-wa" src={whats} width="40px" height="40px" alt="Contato pelo WhatsApp" title="Contato pelo WhatsApp"/> 
	        </a>
        </div>
    );
}

export default WhatsApp