import React from "react";

function QuemSomos() {
  return (
      <div className="container">
        <div className="container3">
            <h1>Quem Somos</h1>
        </div>
          <div className="container2">
            <p> Somos uma empresa com foco em <span className="aceso">Produtividade.</span></p>
            <p> Desenvolvemos <span className="aceso">Serviços de Tecnologia</span> diferenciados.</p>
            <p> Criamos sua <span className="aceso">Loja, Site ou Sistema</span> com Excelência.</p>
            <p> Parcelamos o <span className="aceso">Pagamento</span> que será só após a <span className="aceso">Aprovação do Protótipo.</span></p>
        </div>
      </div>
  );
}

export default QuemSomos;