import React from "react";

function Contato() {
  return (
      <div className="container">
        <div className="container3">
          <h1>Contato</h1>
        </div>
        <div className="container2">
          <p>
            Entre em contato com a gente pelo <a href="https://api.whatsapp.com/send?phone=5571993143374&text=Gostaria%20de%20um%20or%C3%A7amento" target="_blank" rel="noopener noreferrer"><span className="aceso">Whatsapp</span></a>.
          </p>
        </div>
      </div>
  );
}

export default Contato;